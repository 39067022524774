import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Cards, Layout, SEO } from 'components';

const Blog = ({ data, location }) => (
  <Layout location={location}>
    <SEO
      pageSpecificTitle="Vlog"
      pageSpecificDescription="Take a look through our vlogs and read our blogs to learn more about the best ways to live stream from at home, how to engage with your audience and customers, and all our tips & tricks to enjoy with a cuppa."
      pageSpecificThumbnail={data.lightbulbNew.childImageSharp.original.src}
      pathname={location.pathname}
    />
    {console.log('CARDS', data.cards)}
    <div style={{ position: 'relative' }}>
      <Img style={{ width: '100%', height: 600 }} fluid={data.lightbulbNew.childImageSharp.fluid} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          paddingTop: '150px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}>
        <H1>
          Get Broadcasting
          <br />
          Vlog Corner
          <MultiColouredBottomBorder />
        </H1>
        <HeroText>
          Take a look through our vlogs and read our blogs to learn more about the best ways to live
          stream from at home, how to engage with your audience and customers, and all our tips &
          tricks to enjoy with a cuppa.
        </HeroText>
      </div>
      <Cards cards={data.cards.nodes.filter((card) => card.mainImage?.image)} />
    </div>
  </Layout>
);

const H1 = styled.h1`
  color: #fff;
  font-size: calc(1.65rem + 2vw);
  font-weight: 600;
  margin-bottom: 0.125em;
  padding: 0 0.75em 0;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  bottom: -3px;
  height: 3px;
  margin: 0 auto;
  position: relative;
  width: calc(80%);
`;

const HeroText = styled.p`
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.5em;
  margin: 1.125em auto 0;
  max-width: 910px;
  padding: 0 1.75em;
  text-align: center;

  @media screen and (min-width: 500px) {
    font-size: 1.6rem;
  }

  @media screen and (min-width: 800px) {
    font-size: 1.9rem;
  }
`;

export const query = graphql`
  query {
    lightbulbNew: file(relativePath: { eq: "Blog/lightbulb-new.jpg" }) {
      childImageSharp {
        original {
          src
        }
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    cards: allSanityBlogPost(sort: { fields: [publishedAt], order: [DESC] }) {
      nodes {
        id
        title
        author {
          name
        }
        mainImage {
          image {
            asset {
              fixed(width: 350, height: 214) {
                ...GatsbySanityImageFixed
              }
            }
          }
          title
          alt
        }
        publishedAt(formatString: "Do MMMM YYYY")
        summary
        slug {
          current
        }
      }
    }
  }
`;

export default Blog;
